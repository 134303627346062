import * as React from 'react'
import * as css from './LockedLink.module.css'
import { FaLock } from 'react-icons/fa'

const LockedLink = ({ title, tooltip }) => (
  <span className={`${css.locked} with-icon`}>
    <FaLock className={`${css.icon} text-light`} />

    <span className={css.title}>
      {title}

      <small className={css.tooltip}>
        {tooltip || 'Sign in to access this tool'}
      </small>
    </span>
  </span>
)

export default LockedLink
