const dev = process.env.NODE_ENV === 'development'

export default function webpipe(user) {
  if (typeof XMLHttpRequest === 'undefined') return

  // check for user_id
  let id = user?.sub?.includes('auth0') && user?.sub?.split('|').pop()
  if (!id) return

  function requestFirelightUrl(data) {
    let xhr = new XMLHttpRequest()
    let jsonData = `json={"data":${ JSON.stringify(data) }}`

    return new Promise(resolve => {
      // can only be tested on whitelisted domains (that are www only)
      xhr.open('POST', 'https://webpipesso.com/api/make/firelight_cps/t:link') // old

      xhr.setRequestHeader('Origin', dev
        ? 'https://dev.cpsinsurance.com'
        : 'https://www.cpsinsurance.com'
      )
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

      xhr.onload = () => resolve(xhr)
      xhr.send(jsonData)
    })
  }

  async function requestFirelightUrlNewEndpoint(data) {
    // let xhr = new XMLHttpRequest()
    let base64Str = window.btoa(JSON.stringify(data))
    return await fetch(`https://webpipesso.com/api/firelight_cps/${ base64Str }`, {
      headers: {
        'Origin': dev
          ? 'https://dev.cpsinsurance.com'
          : 'https://www.cpsinsurance.com',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
  }

  async function updateFirelightUrl() {
    let json = {
      ID: id,
      first_name: user.name.split(' ')[0],
      last_name: user.name.split(' ')[1],
      user_email: user.email,
      user_login: user.username || user.nickname || user.email,
      display_name: user.name,
      product: { include: false },  // not necesarily a requirement
    }

    const { url } = await requestFirelightUrlNewEndpoint(json)
    let { readyState, responseText } = await requestFirelightUrl(json)

    if (url) return url

    if (readyState === 4) {
      let nodes = new DOMParser().parseFromString(responseText, 'text/html')
      let apiLink = nodes.querySelector('a')

      return !!apiLink && apiLink.href
    }
  }

  // show error fallback if request fails

  return {
    url: null,
    getUrl: updateFirelightUrl,
  }
}
