import * as React from 'react'
import * as css from './Links.module.css'
import { MdKeyboardArrowRight } from 'react-icons/md'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const Accordion = ({ title, icon: Icon, partiallyActive, children }) => {
  const [$open, set$open] = React.useState(false)

  // un-collapse when link is active
  React.useEffect(() => {
    if (!!partiallyActive && window?.location?.pathname?.includes(partiallyActive)) {
      set$open(true)
    }
  }, [partiallyActive])

  return (
    <li className={css.item}>
      <div className={clsx(css.title, $open && css.open)} onClick={() => set$open(!$open)}>
        <Icon />
        {title}
        <MdKeyboardArrowRight className={css.down} />
      </div>

      <ul className={clsx(css.children, $open && css.open)}>
        {children}
      </ul>
    </li>
  )
}

export default Accordion
