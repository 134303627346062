import annuityRate from './sso/annuity-rate'
import webpipe from 'hooks/sso/webpipe'

/*
  Dynamic URLs (relying on Auth0 user data, etc) and
  SSO links in menus go here.
*/

export function useSso(title, user) {
  switch (title) {
    // dynamic SSO links

    case 'Annuity Rate Watch':
    case 'Annuity Rates':
      let annuityRateResult = annuityRate(user)
      if (!annuityRateResult) break
      return annuityRateResult

    case 'Annuity e-App':
    case 'Firelight eApp':
      let webpipeResult = webpipe(user)
      if (!webpipeResult) break
      return webpipeResult

    // SSO links in menus

    case 'LTCPipe Quoting':
      return { url: `/ltc-quoter` }

    case 'Disability Income Insurance Quote':
      return { url: `https://cpsinsurance.assurity.com` }

    case 'Apps & Forms':
    case 'Forms Engine':
    case 'Apps & Forms Engine':
      return { url: `https://cpsinsurance.auth0.com/samlp/pB6NW2evD5feW7XMp2cCRal7Eo5X3yM6?RelayState=https://formspipe.ipipeline.com/?GAID=1028` }
      // return { url: `https://formspipe.ipipeline.com/?r=-6248268581343188947&GAID=1028` }

    case 'My Business Portal':
      return { url: `https://cpsinsurance.auth0.com/samlp/pB6NW2evD5feW7XMp2cCRal7Eo5X3yM6?RelayState=https://dataview.ipipeline.com/?GAID=1028` }

    // case 'Traditional LTC':
    //   return { url: 'https://www.ltcselect.com/admin.asp' }

    default:
      break
  }

  return { url: null }
}
