import * as React from 'react'
import * as css from './LoginState.module.css'
import { Link } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'

const LoginState = () => {
  const {
    isAuthenticated,
    user,
    logout,
    loginWithRedirect,
  } = useAuth0()

  function handleLogout() {
    localStorage.removeItem('user_metadata')
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className={css.root}>
      {isAuthenticated
        ? <>
          <div className={`${css.welcome} tablet-and-up`}>
            Welcome<br/>
            {user.given_name || user.name || user.nickname.split('@')[0]}!
          </div>

          <button className={`${css.btn} button sm`} onClick={handleLogout}>
            Log out
          </button>
        </> : <>
          <Link to="/register" className={`${css.btn} button sm`}>Register</Link>

          <button className={`${css.btn} button sm`} onClick={loginWithRedirect}>
            Sign In
          </button>
        </>
      }
    </div>
  )
}

export default LoginState
