import * as React from 'react'
import * as css from './MegaMenu.module.css'
import { GlobalContext } from 'components/common/GlobalContext'
import { useAuth0 } from '@auth0/auth0-react'
import { useDeviceSelectors } from 'react-device-detect'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { CgChevronDoubleRight } from 'react-icons/cg'
import { Link } from 'gatsby'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

export default function MegaMenu({ title, url, children }) {
  const { $megaMenu, set$megaMenu } = React.useContext(GlobalContext)
  const { isAuthenticated } = useAuth0()

  const [selector] = useDeviceSelectors()

  function handleClick() {
    if (!selector.isMobile) return

    if ($megaMenu === title) {
      set$megaMenu(false)
    } else {
      set$megaMenu(title)
    }
  }

  const isOpen = $megaMenu === title && 'is-open'

  return (
    <li
      className={css.root}
      onMouseEnter={() => (!selector.isMobile ? set$megaMenu(title) : null)}
      onMouseLeave={() => (!selector.isMobile ? set$megaMenu(false) : null)}
    >
      {selector.isMobile ? (
        <div
          className={clsx(css.title, isOpen, 'nav--link')}
          onClick={handleClick}
        >
          {title}
          <MdKeyboardArrowRight className={clsx(css.icon, isOpen, 'tablet')} />
        </div>
      ) : (
        <Link className={clsx(css.title, isOpen, 'nav--link')} to={url}>
          {title}
          <MdKeyboardArrowRight className={clsx(css.icon, isOpen, 'tablet')} />
        </Link>
      )}

      <div className={clsx(css.contents, isOpen, isAuthenticated && css.small)}>
        <div className={css.inner}>
          <div className={`${css.grid} container`}>
            <div className={css.parent}>
              <Link className="link with-icon uppercase" to={url}>
                {title}
                <CgChevronDoubleRight />
              </Link>
            </div>
            {children}
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className={clsx(css.closer, 'desktop-and-up')}
          onMouseEnter={() => set$megaMenu(false)}
        />
      )}
    </li>
  )
}
