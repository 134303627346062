import * as React from 'react'
import { GlobalContext } from 'components/common/GlobalContext'
import { Link } from 'gatsby'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useAuth0 } from '@auth0/auth0-react'
import joinReference from 'utils/join-reference'
import newtab from 'utils/newtab'
import { useSso } from 'hooks/useSso'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */

const Contents = ({ label, style, children }) => (
  <>
    {label}
    {children}
    {style === 'link' && <MdKeyboardArrowRight />}
  </>
)

export default function CTA(props) {
  const { loginWithRedirect } = useAuth0()
  const { isAgent } = React.useContext(GlobalContext)

  const { internalUrl, url, className } = props

  const styles = clsx(
    props.style,
    props.style === 'link' && 'with-icon',
    className,
  )

  const isLoginUrl = /^\/(register)/.test(url)

  const internalUrlSlug =
    internalUrl &&
    `/${joinReference(internalUrl)?.seo.slug.current || internalUrl?.seo.slug.current}`

  const isExternal = url?.startsWith('http')
  const external = isExternal || url === '/ltc-quoter' ? { ...newtab } : {}

  if (isAgent && isLoginUrl) return null

  // ARW
  if (props.label === 'View Annuity Rates')
    return (
      <LinkSSO title="Annuity Rate Watch" className={styles}>
        <Contents {...props} />
      </LinkSSO>
    )

  return isLoginUrl ? (
    <button className={styles} onClick={loginWithRedirect}>
      <Contents {...props} />
    </button>
  ) : isExternal || url === '/ltc-quoter' ? (
    <a className={styles} href={url} {...external}>
      <Contents {...props} />
    </a>
  ) : (
    <Link className={styles} to={internalUrlSlug || url}>
      <Contents {...props} />
    </Link>
  )
}

const LinkSSO = ({ title, ...props }) => {
  const { user } = useAuth0()
  const { url } = useSso(title, user)

  return !!url && <a href={url} target="_blank" {...props} />
}
