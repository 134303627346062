import * as React from 'react'
import Helmet from 'react-helmet'
import { useAuth0 } from '@auth0/auth0-react'
import { GlobalContext, GlobalProvider } from 'components/common/GlobalContext'
import { Loader } from 'layouts/Loading'
import AnnouncementBar from 'components/header/AnnouncementBar'
import Header from 'components/header/Header'
import SidebarLayout from 'components/sidebar/SidebarLayout'
import Footer from 'components/footer/Footer'
import { graphql, useStaticQuery } from 'gatsby'
import 'styles/global.css'

const App = ({ className, children }) => {
  const { isAgent } = React.useContext(GlobalContext)

  return isAgent
    ? <SidebarLayout>
      <main className={className} style={{ minWidth: 0 }}>{children}</main>
    </SidebarLayout>
    : <main className={className}>{children}</main>
}

const DefaultLayout = ({ title, description, ...props }) => {
  const { isLoading } = useAuth0()
  const { sanitySite } = useStaticQuery(query)

  return <>
    <Helmet
      titleTemplate={`%s | ${sanitySite.title}`}
      defaultTitle={sanitySite.title}
    >
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>


    {isLoading ? <Loader /> :
      <GlobalProvider>
        <AnnouncementBar />
        <Header />
        <App {...props} />
        <Footer />
      </GlobalProvider>
    }
  </>
}

export default DefaultLayout

const query = graphql`query DefaultLayout {
  sanitySite {
    title
  }
}`
