import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const SidebarContext = React.createContext(null)

export const SidebarProvider = ({ children }) => {
  const {
    sanityQuotePg,
    sanityApplyPg,
    allSanityUnderwritingSubPg,
  } = useStaticQuery(query)

  const value = {
    sanityQuotePg,
    sanityApplyPg,
    allSanityUnderwritingSubPg,
  }

  return <SidebarContext.Provider value={value}>
    {children}
  </SidebarContext.Provider>
}

const query = graphql`query SidebarLinks {
  sanityQuotePg {
    sso {
      items {
        title
        cta { url }
      }
    }
  }
  sanityApplyPg {
    sso {
      items {
        title
        cta { url }
      }
    }
  }
  allSanityUnderwritingSubPg(sort: {fields: order}) {
    nodes {
      title
      seo { slug { current } }
    }
  }
}`
