import * as React from 'react'
import * as css from './SidebarTrigger.module.css'
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md'
import clsx from 'clsx'

const SidebarTrigger = ({ state }) => {
  const [$open, set$open] = state

  return (
    <button
      className={clsx(css.root, $open && css.opened, 'with-icon desktop')}
      onClick={() => set$open(!$open)}
    >
      Tools
      {$open ? <MdClose /> : <MdKeyboardArrowRight />}
    </button>
  )
}

export default SidebarTrigger
