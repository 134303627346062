import * as React from 'react'
import * as css from './Loading.module.css'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Loader = () => {
  const { logo } = useStaticQuery(query)

  return (
    <main className={css.root}>
      <div className="styled-corner-left styled-corner-right">
        <div className={`${css.inner} text-center`}>
          <GatsbyImage
            image={getImage(logo)}
            alt="CPS Insurance logo"
            objectFit="contain"
          />
          <h1 className={`${css.title} h1 cps-blue`}>Loading...</h1>
        </div>
      </div>
    </main>
  )
}

const LoadingLayout = () => {
  const { sanitySite } = useStaticQuery(query)

  return <>
    <Helmet titleTemplate={`%s | ${sanitySite.title}`}>
      <title>Loading</title>
      <link rel="icon" href="/favicon.png" sizes="32x32" />
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
    </Helmet>

    <Loader />
  </>
}

export default LoadingLayout

const query = graphql`query LoadingLayout {
  sanitySite {
    title
  }
  logo: file(relativePath: {eq: "cps-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, height: 65)
    }
  }
}`
