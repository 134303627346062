import { graphql, useStaticQuery } from "gatsby"

/* eslint-disable import/no-anonymous-default-export */

export default ({ _ref }) => {
  const {
    sanityQuotePg,
    sanityApplyPg,
    sanityUnderwritingPg,
    allSanityProductPg,
    allSanityPage,
  } = useStaticQuery(query)

  const references = [
    sanityQuotePg,
    sanityApplyPg,
    sanityUnderwritingPg,
    ...allSanityProductPg.nodes,
    ...allSanityPage.nodes,
  ]

  return references.find(({ id, _id }) => [id, _id].includes(_ref))
}

const query = graphql`query joinReference {
  sanityQuotePg { id _id seo { slug { current } } }
  sanityApplyPg { id _id seo { slug { current } } }
  sanityUnderwritingPg { id _id seo { slug { current } } }
  allSanityProductPg { nodes { id _id seo { slug { current } } } }
  allSanityPage { nodes { id _id seo { slug { current } } } }
}`
