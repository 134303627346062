import * as React from 'react'
import * as css from './AnnouncementBar.module.css'
import { graphql, Link, useStaticQuery } from 'gatsby'
import newtab from 'utils/newtab'

const AnnouncementBar = () => {
  const { enabled, text, internalUrl, url } = useStaticQuery(query).sanityAnnouncement

  const external = /^https?:\/\//g.test(url)
    ? {...newtab}
    : {}

  return enabled ? (
    <aside className={css.root}>
      {internalUrl
        ? (
          <Link to={internalUrl.seo.slug.current}>
            <p>{text}</p>
          </Link>
        )
        : url ? (
          <a href={url} {...external}>
            <p>{text}</p>
          </a>
        )
        : <p>{text}</p>
      }
    </aside>
  ) : null
}

export default AnnouncementBar

const query = graphql`query AnnouncementBar {
  sanityAnnouncement {
    enabled
    text
    internalUrl { ...internalUrlSlug }
    url
  }
}`
