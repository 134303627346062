import * as React from 'react'
import * as css from './Social.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import newtab from 'utils/newtab'
import { IoLogoLinkedin } from 'react-icons/io5'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import clsx from 'clsx'

const Icon = ({ url }) => {
  if (url.includes('facebook')) return <FaFacebook />
  if (url.includes('twitter')) return <FaTwitter />
  if (url.includes('linkedin')) return <IoLogoLinkedin />

  return null
}

const Social = ({ className }) => {
  const { sanitySite } = useStaticQuery(query)

  return (
    <ul className={clsx(className, css.root, 'h2')}>
      {sanitySite.social.map((url, key) => (
        <li key={key}>
          <a className={`${css.link} text-light`} href={url} {...newtab}>
            <Icon url={url} />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Social

const query = graphql`query Social {
  sanitySite {
    social
  }
}`
