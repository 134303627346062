import * as React from 'react'
import * as css from './Search.module.css'
import { SearchContext } from 'components/search/SearchContext'
import { Link, navigate } from 'gatsby'
import { IoIosSearch } from 'react-icons/io'

const Search = () => {
  const { $query, set$query } = React.useContext(SearchContext)

  function handleChange({ target }) {
    set$query(target.value)
  }

  function handleKeyUp({ key, target }) {
    if (!!target.value && key === 'Enter') {
      navigate(`/search?q=${ target.value }`)
    }
  }

  return (
    <div className={css.root}>
      <div className={css.searchbar}>
        <IoIosSearch className={css.icon} />
        <label>
          <input
            className={css.input}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            type="search"
            placeholder="Search"
          />
        </label>
      </div>

      {!!$query && (
        <p className={css.go}>
          <Link to={`/search?q=${ $query }`} className="link">
            Search for "{$query}"
          </Link>
        </p>
      )}
    </div>
  )
}

export default Search
