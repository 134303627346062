import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export default function useUserMetadata() {
  const [$userMetadata, set$userMetadata] = React.useState(null)

  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()

  React.useEffect(() => {
    async function fetchUserMetadata() {
      // use localStorage if already stored and not make API call
      if (!!localStorage.getItem('user_metadata')) {
        set$userMetadata(JSON.parse(localStorage.getItem('user_metadata')))
        return
      }

      // make API call
      const accessToken = await getAccessTokenSilently({
        audience: `https://cpsinsurance.auth0.com/api/v2/`,
        scope: 'read:current_user',
      })

      const userDetailsByIdUrl = `https://cpsinsurance.auth0.com/api/v2/users/${ user.sub }`
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${ accessToken }`,
        },
      })

      const { user_metadata } = await metadataResponse.json()

      // store in localStorage
      localStorage.setItem('user_metadata', JSON.stringify(user_metadata))
      set$userMetadata(user_metadata)
    }

    isAuthenticated && fetchUserMetadata()
  }, [isAuthenticated, getAccessTokenSilently, user?.sub])

  return $userMetadata
}
