import * as React from 'react'
import * as css from './Footer.module.css'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Social from 'components/common/Social'
import FooterLinks from './FooterLinks'

const Footer = () => {
  const { logo } = useStaticQuery(query)

  return (
    <footer className={`${css.root} padding-y`}>
      <div className={`${css.grid} container-lg padding-x`}>
        <div className={css.logo}>
          <Link to="/">
            <GatsbyImage
              className={css.img}
              image={getImage(logo)}
              alt="CPS Insurance logo"
            />
          </Link>
        </div>

        <div className={css.links}>
          <Social className={css.social} />
          <FooterLinks />
        </div>

        <div className={`${css.bottom} text-light`}>
          <p>
            &copy; {(new Date()).getFullYear()} CPS Insurance Services, Inc. All Rights Reserved.
            <br />
            California Corporate License #0571612
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const query = graphql`query Footer {
  logo: file(relativePath: {eq: "cps-logo-white.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, height: 150)
    }
  }
}`
