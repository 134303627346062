import * as React from 'react'
import * as css from './FooterLinks.module.css'
import { Link } from 'gatsby'

const Item = props => (
  <li>
    <Link className={`${css.link} text-light`} {...props} />
  </li>
)

const FooterLinks = () => (
  <ul className={css.root}>
    <Item to="/why-cps">Why CPS</Item>
    <Item to="/contact-us">Contact Us</Item>
    <Item to="/news">News</Item>
    <Item to="/privacy-policy">Privacy Policy</Item>
  </ul>
)

export default FooterLinks
