import * as React from 'react'
import * as css from './SidebarLinks.module.css'
import { SidebarContext } from './SidebarContext'
import { ImBooks } from 'react-icons/im'
import { useSso } from 'hooks/useSso'
import { useAuth0 } from '@auth0/auth0-react'
import Accordion from './Accordion'
import { ChildLink, SingleLink, SSOLink } from './Links'
import {
  FaCalculator,
  FaMoneyCheckAlt,
  FaClipboardList,
  FaPenNib,
  FaFileContract,
  FaFileUpload,
  FaDoorOpen,
} from 'react-icons/fa'
import { MdEvent, MdCreditCard } from 'react-icons/md'

export default function SidebarLinks() {
  const { sanityQuotePg, sanityApplyPg, allSanityUnderwritingSubPg } =
    React.useContext(SidebarContext)

  const { user } = useAuth0()

  return (
    <ul className={css.root}>
      <SingleLink
        url="/products/life-insurance/needs-calculator"
        icon={FaCalculator}
      >
        Life Insurance Needs Calculator
      </SingleLink>

      <Accordion
        title="Quotes"
        icon={FaMoneyCheckAlt}
        partiallyActive="/online-quoting"
      >
        <ChildLink url="/services-tools/online-quoting">Overview</ChildLink>
        {sanityQuotePg.sso.items.map((item, key) => (
          <SSOLink isChild {...item} key={key} />
        ))}
      </Accordion>

      <SingleLink
        external
        url={useSso('Apps & Forms Engine', user).url}
        icon={FaClipboardList}
      >
        Apps & Forms Engine
      </SingleLink>

      {sanityApplyPg.sso.items.map((item, key) => (
        <SSOLink {...item} key={key} />
      ))}

      <SingleLink url="/contracting" icon={FaFileContract}>
        Contracting
      </SingleLink>

      <Accordion
        title="Underwriting"
        icon={FaPenNib}
        partiallyActive="/underwriting"
      >
        <ChildLink url="/services-tools/underwriting">Overview</ChildLink>
        {allSanityUnderwritingSubPg.nodes.map((pg, key) => (
          <ChildLink
            url={`/services-tools/underwriting/${pg.seo.slug.current}`}
            key={key}
          >
            {pg.title}
          </ChildLink>
        ))}
      </Accordion>

      <SingleLink url="/document-upload" icon={FaFileUpload}>
        Document Upload
      </SingleLink>
      <SingleLink url="/point-of-sales-support" icon={MdCreditCard}>
        Point of Sale Support
      </SingleLink>
      <SingleLink url="/services-tools/marketing-library" icon={ImBooks}>
        Marketing Library
      </SingleLink>
      <SingleLink url="/services-tools/my-portal" icon={FaDoorOpen}>
        My Business Portal
      </SingleLink>
      <SingleLink url="/events-and-webinars" icon={MdEvent}>
        Events & Webinars
      </SingleLink>
    </ul>
  )
}
