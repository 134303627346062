import * as React from 'react'
import * as css from './MenuToggle.module.css'
import { GlobalContext } from '../common/GlobalContext'
import { MdMenu, MdClose } from 'react-icons/md'

const MenuToggle = () => {
  const { $headerMenuOpen, set$headerMenuOpen } = React.useContext(GlobalContext)

  return (
    <button className={`${css.root} tablet`} onClick={() => set$headerMenuOpen(!$headerMenuOpen)}>
      {$headerMenuOpen ? <MdClose /> : <MdMenu />}
    </button>
  )
}

export default MenuToggle
