import * as React from 'react'
import * as css from './SidebarLayout.module.css'
import Sidebar from './Sidebar'

const SidebarLayout = ({ children }) => (
  <div className={css.root}>
    <Sidebar />

    {children}
  </div>
)

export default SidebarLayout
