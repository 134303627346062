import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const SearchContext = React.createContext(null)

function processSinglePage(type, { seo }) {
  return ({
    title: seo.title,
    seo,
    type,
  })
}

function processPages(type = 'Page', pages) {
  return pages.map(page => ({
    ...page,
    type,
  }))
}

export const SearchProvider = ({ children }) => {
  const {
    sanityAboutPg,
    sanityContactPg,
    sanityBlogSettings,
    allSanityProductPg,
    sanityQuotePg,
    sanityApplyPg,
    sanityUnderwritingPg,
    allSanityUnderwritingSubPg,
    allSanityPage,
    allSanityBlogPost,
  } = useStaticQuery(query)

  const allPages = [
    processSinglePage('Page', sanityAboutPg),
    processSinglePage('Page', sanityContactPg),
    processSinglePage('Blog', sanityBlogSettings),
    processSinglePage('Page', sanityQuotePg),
    processSinglePage('Page', sanityApplyPg),
    processSinglePage('Page', sanityUnderwritingPg),
    ...processPages('Line of Business', allSanityProductPg.nodes),
    ...processPages('Underwriting', allSanityUnderwritingSubPg.nodes),
    ...processPages('Page', allSanityPage.nodes),
    ...processPages('Blog', allSanityBlogPost.nodes),

    { title: 'Marketing Library', url: '/services-tools/marketing-library' },
    { title: 'My Business Portal', url: '/services-tools/marketing-library' },
    { title: 'Document Upload', url: '/document-upload' },
  ]

  const [$query, set$query] = React.useState(null)

  const value = {
    allPages,
    $query, set$query,
  }

  return <SearchContext.Provider value={value}>
    {children}
  </SearchContext.Provider>
}

const query = graphql`query SearchLinks {
  sanityAboutPg {
    seo { title slug { current } }
  }
  sanityContactPg {
    seo { title slug { current } }
  }
  sanityBlogSettings {
    seo { title slug { current } }
  }
  allSanityProductPg {
    nodes { title seo { slug { current } } }
  }
  sanityQuotePg {
    seo { title slug { current } }
  }
  sanityApplyPg {
    seo { title slug { current } }
  }
  sanityUnderwritingPg {
    seo { title slug { current } }
  }
  allSanityUnderwritingSubPg(sort: {fields: order}) {
    nodes { title seo { slug { current } } }
  }
  allSanityPage {
    nodes { title seo { slug { current } } }
  }
  allSanityBlogPost {
    nodes { title seo { slug { current } } }
  }
}`
