import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSso } from 'hooks/useSso'
import { GlobalContext } from 'components/common/GlobalContext'
import Cta from 'components/common/CTA'
import { MdOpenInNew } from 'react-icons/md'
import LockedLink from './LockedLink'

const ProductTool = ({ title, cta, className }) => {
  const { user } = useAuth0()
  const { isAgent } = React.useContext(GlobalContext)

  const sso = useSso(title, user)
  const [$url, set$url] = React.useState(sso.url || cta?.url)

  React.useEffect(() => {
    if (!!sso.getUrl) {
      (async () => {
        let url = await sso.getUrl()
        if (!!url) {
          set$url(url)
        }
      })()
    }
  }, [sso, set$url])

  return (
    <p>
      {(isAgent && !!$url)
        ? <Cta label={title} url={$url} className={`${className} with-icon`}>
          {$url?.startsWith('http') && <MdOpenInNew className="text-light" />}
        </Cta>
        : <LockedLink title={title} tooltip={(isAgent && !$url) && 'Not available'} />
      }
    </p>
  )
}

export default ProductTool
