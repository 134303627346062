import * as React from 'react'
import * as css from './Links.module.css'
import { useSso } from 'hooks/useSso'
import { useAuth0 } from '@auth0/auth0-react'
import { MdLaunch } from 'react-icons/md'
import { FaLock, FaLink } from 'react-icons/fa'
import { Link } from 'gatsby'
import newtab from 'utils/newtab'
import clsx from 'clsx'

export const LockedLink = ({ children }) => (
  <li className={css.child}>
    <span className="with-icon text-light">
      {children}
      <FaLock />
    </span>
  </li>
)

export const ChildLink = ({ url, external, children }) => (
  <li className={css.child}>
    {external
      ? (
        <a className="link with-icon" href={url} {...newtab}>
          {children}
          <MdLaunch className="text-light" />
        </a>
      ) : (
        <Link className="link" activeClassName={css.active} to={url}>
          {children}
        </Link>
      )
    }
  </li>
)

export const SingleLink = ({ url, icon: Icon, external, children }) => (
  <li className={css.item}>
    {external
      ? (
        <a className={css.title} href={url} {...newtab}>
          <Icon />
          {children}
          <MdLaunch />
        </a>
      ) : (
        <Link className={css.title} activeClassName={css.active} to={url}>
          <Icon />
          {children}
        </Link>
      )
    }
  </li>
)

export const SSOLink = ({ title, cta, isChild }) => {
  const { user } = useAuth0()
  const sso = useSso(title, user)
  const [$url, set$url] = React.useState(sso.url || cta?.url)
  const [$locked, set$locked] = React.useState(false)

  React.useEffect(() => {
    if (!sso.getUrl) return
    (async () => {
      let url = await sso.getUrl()
      if (!!url) {
        set$url(url)
      } else {
        set$locked(true)
      }
    })()
  }, [sso, set$url, set$locked])

  if (isChild) {
    return (
      !!$url ? <ChildLink url={$url} external>{title}</ChildLink> :
      !!$locked ? <LockedLink>{title}</LockedLink>
      : null
    )
  }

  return (
    !!$url ? <SingleLink url={$url} icon={FaLink} external>{title}</SingleLink> :
    !!$locked ? (
      <li className={css.item}>
        <span className={clsx(css.title, 'text-light')}>
          <FaLink />
          {title}
          <FaLock title="Not available" />
        </span>
      </li>
    ) : null
  )
}
