import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import useUserMetadata from 'hooks/userMetadata'

export const GlobalContext = React.createContext(null)

export const GlobalProvider = ({ children }) => {
  const [$headerMenuOpen, set$headerMenuOpen] = React.useState(false)
  const [$megaMenu, set$megaMenu] = React.useState(false)

  const { isAuthenticated } = useAuth0()
  const userMetadata = useUserMetadata()

  const isAgent = isAuthenticated && userMetadata?.USER_ROLE === 'Agent'

  const value = {
    $headerMenuOpen, set$headerMenuOpen,
    $megaMenu, set$megaMenu,
    isAgent,
  }

  return <GlobalContext.Provider value={value}>
    {children}
  </GlobalContext.Provider>
}
