import * as React from 'react'
import { GlobalContext } from 'components/common/GlobalContext'
import { useSso } from 'hooks/useSso'
import LockedLink from 'components/header/LockedLink'
import { Link } from 'gatsby'
import { MdOpenInNew } from 'react-icons/md'
import newtab from 'utils/newtab'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/anchor-has-content */

const SubnavLink = ({ locked, title, directUrl, sso, className }) => {
  const { isAgent } = React.useContext(GlobalContext)

  const url = useSso(sso).url || directUrl

  const isExternal = url.startsWith('http')

  return (
    <div>
      {(locked && !isAgent)
        ? <LockedLink title={title} />
        : <div>
          {isExternal
            ? (
              <a className={clsx('link with-icon', className)} href={url} {...newtab}>
                {title}
                <MdOpenInNew className="text-light" />
              </a>
            )
            : <Link className={clsx('link', className)} to={url}>{title}</Link>
          }
        </div>
      }
    </div>
  )
}

export default SubnavLink
