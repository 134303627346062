import * as React from 'react'
import * as css from './Logo.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link, graphql, useStaticQuery } from 'gatsby'

const Logo = () => {
  const { logo } = useStaticQuery(query)

  return (
    <div className={css.root}>
      <Link to="/">
        <GatsbyImage
          className={css.img}
          image={getImage(logo)}
          alt="CPS Insurance logo"
          objectFit="contain"
          objectPosition="left"
        />
      </Link>
    </div>
  )
}

export default Logo

const query = graphql`query Header {
  logo: file(relativePath: {eq: "cps-logo-sm.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, height: 65)
    }
  }
}`
