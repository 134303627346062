import md5 from 'md5'

export default function annuityRate(user) {
  const ptid = user?.sub.split('|').pop()

  const pass = 'apisconnect'  // alternative password but doesn't work: "annuitiesarefun"
  const key = md5(ptid + pass)

  if (!ptid) return

  return {
    url: `https://members.annuityratewatch.com/cpsinsurance/?ptid=${ ptid }&key=${ key }&profile=yes`
  }
}
