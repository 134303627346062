import * as React from 'react'
import * as css from './Sidebar.module.css'
import { SidebarProvider } from './SidebarContext'
import { SearchProvider } from 'components/search/SearchContext'
import SidebarTrigger from './SidebarTrigger'
import Search from './Search'
import SidebarLinks from './SidebarLinks'
import clsx from 'clsx'

const Sidebar = () => {
  const [$open, set$open] = React.useState(false)

  return (
    <SidebarProvider>
      <aside className={css.root}>
        <SidebarTrigger state={[$open, set$open]} />

        <div className={clsx(css.wrapper, !$open && 'large')}>
          <div className={css.inner}>
            <SearchProvider>
              <Search />
            </SearchProvider>

            <SidebarLinks />
          </div>
        </div>
      </aside>
    </SidebarProvider>
  )
}

export default Sidebar
